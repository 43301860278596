import React, { useEffect, useState } from "react";
import locatorx from "../../assets/locatorx.png";
import request from "superagent";
import { Form } from "react-bootstrap";
import NotificationModal from "../../components/NotificationModal";
import TermsandPrivacy from "../../components/TermsandPrivacy";
import { validatePasswordStrength } from "../../utils/passwordStrength";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  TextField,
  InputLabel,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.success.main,
    color: "white",
    width: "100%",
    "&:hover": {
      backgroundColor: "#45793A",
      color: "#FFF",
    },
  },
  buttonContainer: {
    marginTop: "2rem",
  },
  container: {
    padding: "0 2rem",
  },
  formControl: {
    margin: "1rem 0",
    width: "100%",
  },
  input: {
    margin: "2rem 0",
  },
}));

export default function ResetPassword(props) {
  const classes = useStyles();
  const { token, appUserId } = props.match.params;
  const [state, setState] = useState({
    appUserId,
    confirmationModalColor: "bg-success",
    confirmationModalShow: false,
    confirmationText: "",
    confirmPassword: "",
    foo: false,
    password: "",
    showNewPassword: false,
    showTooltip: false,
    showVerifyPassword: false,
    submitSuccess: false,
    token,
  });
  const {
    confirmationText,
    newPassword = "",
    showTooltip,
    showNewPassword,
    showVerifyPassword,
    verifyPassword,
    submitSuccess,
  } = state;

  useEffect(() => {
    localStorage.setItem("token", token);
    localStorage.setItem("appUserId", appUserId);
  }, [appUserId, token]);

  function handleSubmit(event) {
    const { newPassword, verifyPassword } = state;
    const { token } = props.match.params;
    let body = {
      newPassword,
      verifyPassword,
    };

    // Verifies the passwords match
    if (newPassword !== verifyPassword) {
      return alert("Error: Passwords do not match");
    } else if (!validatePasswordStrength(newPassword)) {
      setState((prevState) => ({ ...prevState, showTooltip: true }));
    } else
      request
        .post(`${props.apiUrl}appUsers/${token}/forgotPassword`)
        .send(body)
        .then((response) => {
          if (response.body.success)
            setState((prevState) => ({
              ...prevState,
              submitSuccess: true,
              validated: true,
            }));
          else alert("Error: " + response.body.error);
        });
  }

  const requiredField = (
    <Form.Control.Feedback
      className="bg-primary invalid-feedback w-25 rounded text-white bg-danger px-1 font-weight-bold text-center position-relative speech-bubble mt-2"
      type="invalid"
    >
      Required Field
    </Form.Control.Feedback>
  );
  let modalClose = () =>
    setState((prevState) => ({ ...prevState, modalShow: false }));

  return (
    <Grid className={classes.container} container>
      {submitSuccess ? (
        <Grid item xs={12}>
          <Grid item xs={12} className="my-3">
            <img alt="company logo" src={locatorx} width="275" />
          </Grid>
          <Grid item xs={12} className="w-100 d-flex flex-column mt-5">
            <span className="my-2">
              <h3>Password has been reset!</h3>
            </span>
            <span className="my-2">
              <h3>Thank You!</h3>
            </span>
            <span className="my-2">
              <h3>Retry Signing In!</h3>
            </span>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid item xs={12} className="my-3 text-center">
            <img alt="company logo" src={locatorx} width="275" />
          </Grid>

          {/* Password Disclaimer */}
          {showTooltip ? (
            <>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <Typography variant="caption">
                  The requirements for a password are:
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Typography variant="caption">
                  - At least 8 characters <br />- A mixture of both uppercase
                  and lowercase letters <br />- A mixture of letters and numbers{" "}
                  <br />- Inclusion of at least one special character, e.g., ! @
                  # ? ]{" "}
                </Typography>
              </Grid>
            </>
          ) : null}

          {/* Password */}
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <TextField
                field="newPassword"
                label="Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setState((prevState) => ({
                            ...prevState,
                            showNewPassword: !prevState.showNewPassword,
                          }));
                        }}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                type={state.showNewPassword ? "text" : "password"}
                id="password"
                onChange={(event) => {
                  event.persist();
                  // If the tooltip is fired, this checks whether or not we need to close it
                  if (
                    !validatePasswordStrength(event.target.value) ||
                    !validatePasswordStrength(verifyPassword)
                  ) {
                    setState((prevState) => ({
                      ...prevState,
                      showTooltip: true,
                    }));
                  } else {
                    setState((prevState) => ({
                      ...prevState,
                      showTooltip: false,
                    }));
                  }
                  setState((prevState) => ({
                    ...prevState,
                    newPassword: event.target.value,
                  }));
                }}
                required
                variant="outlined"
                value={newPassword}
              />
            </FormControl>
          </Grid>

          {/* Confirm Password */}
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <TextField
                field="verifyPassword"
                variant="outlined"
                label="Confirm Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setState((prevState) => ({
                            ...prevState,
                            showVerifyPassword: !prevState.showVerifyPassword,
                          }));
                        }}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                      >
                        {showVerifyPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                type={state.showVerifyPassword ? "text" : "password"}
                id="verifyPassword"
                onChange={(event) => {
                  event.persist();
                  // If the tooltip is fired, this checks whether or not we need to close it
                  if (
                    !validatePasswordStrength(event.target.value) ||
                    !validatePasswordStrength(newPassword)
                  ) {
                    setState((prevState) => ({
                      ...prevState,
                      showTooltip: true,
                    }));
                  } else {
                    setState((prevState) => ({
                      ...prevState,
                      showTooltip: false,
                    }));
                  }
                  setState((prevState) => ({
                    ...prevState,
                    verifyPassword: event.target.value,
                  }));
                }}
                required
                value={verifyPassword}
              />
            </FormControl>
          </Grid>

          <Grid className={classes.buttonContainer} item xs={12}>
            <button
              type="button"
              className="btn btn-primary btn-block"
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </button>
          </Grid>
        </>
      )}
      <TermsandPrivacy />
    </Grid>
  );
}
