import React from "react";

export default function IconWithArrow(props) {
  const {
    icon = "",
    label = false,
    link = null,
    showArrow = true,
    showUnderline,
    newWindow = false
  } = props;
  // Links can either be handled by this component or react-router. Which is why we have this ternary
  const item =
    link == null ? (
      <div className="d-flex">
        <div className={`mr-3 ${icon ? "" : "pr-1"}`}>
          <i className={icon} />
        </div>
        <div
          className={`d-flex justify-content-between w-100 ${
            icon ? "" : "pl-3"
          }`}
        >
          <div className="d-inline">
            <h5 className="settingsLinks align-middle">{label}</h5>
          </div>
          {showArrow ? <i className="fas fa-chevron-right fa-lg"></i> : null}
        </div>
      </div>
    ) : (
      <a
        className="d-flex text-white"
        target={newWindow ? "_blank" : ""}
        rel="noopener noreferrer"
        href={link}
      >
        <div className={`mr-3 ${icon ? "" : "pr-1"}`}>
          <i className={icon} />
        </div>
        <div
          className={`d-flex justify-content-between w-100 ${
            icon ? "" : "pl-3"
          }`}
        >
          <div className="d-inline">
            <h5 className="settingsLinks align-middle">{label}</h5>
          </div>
          {showArrow ? <i className="fas fa-chevron-right fa-lg"></i> : null}
        </div>
      </a>
    );
  return (
    <div className="w-100 my-3 text-white">
      {item}
      {showUnderline ? (
        <div className="ml-4 pl-3 mt-3">
          <div className="border-bottom w-100" />
        </div>
      ) : null}
    </div>
  );
}
