import React from "react";

export default function Toggle(props) {
  const { label, onChange, checked = false } = props
  console.log(checked,'danfoo')
  return (
    <div className="d-flex justify-content-between mt-2">
      <div>{label}</div>
      <div className={`${checked  ? "active-slider" : "danfoo"} custom-control custom-switch`}>
        <input
          type="checkbox"
          className="custom-control-input"
          id={label}
          onChange={e=>{onChange(e)}}
          checked={checked}
        />
        <label className="custom-control-label" htmlFor={label} />
      </div>
    </div>
  );
}
