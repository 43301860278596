import React from "react";
import Toggle from "../../components/Toggle";
import IconWithArrow from "../../components/IconWithArrow";
import settings from "../../assets/settings.png";
import { NavLink } from "react-router-dom";

export default class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sound: prompt("get=sound"),
      vibrate: prompt("get=vibrate")
    };
  }

  handleToggle(value) {
    prompt(`toggle=${value}`);

    let item = prompt(`get=${value}`);
    this.setState({ [value]: item });
  }

  render() {
    const { sound, vibrate } = this.state;
    return (
      <div className="container text-white py-3">
        <div className="row">
          <div className="col-12 my-2 d-flex">
            <div className="mr-3">
              <i className="fas fa-bell fa-2x" />
            </div>
            <div className="d-flex flex-column justify-content-center w-100">
              <h5 className="mt-1">Notifications</h5>
              <div className="ml-2">
                <div className="notes">
                  Notified when a successful scan is made.
                </div>
                <div className="toggle">
                  <Toggle
                    label="Sound"
                    className={sound === "true" ? 'active-slider' : ''}
                    onChange={() => {
                      this.handleToggle("sound");
                    }}
                    checked={sound === "true" ? true : false}
                  />
                  <Toggle
                    label="Vibrate"
                    className={vibrate === "true" ? 'active-slider' : ''}
                    onChange={() => {
                      this.handleToggle("vibrate");
                    }}
                    checked={vibrate === "true" ? true : false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 my-3 d-flex">
            <div className="mr-2">
              <img src={settings} className="custom-icon" alt="icon" />
            </div>
            <div
              onClick={() => {
                prompt("settings");
              }}
              className="d-flex flex-column justify-content-center w-100"
            >
              <h5 className="mt-1">Device Settings</h5>
              <div className="ml-2">
                <div className="notes">
                  Manage your device settings for LX LogisticsPro.
                </div>
                <h6 className="mt-2">View</h6>
              </div>
            </div>
          </div>
          <div className="col-12">
            <NavLink activeClassName="active" to="/about">
              <IconWithArrow icon="fas fa-info-circle fa-2x" label="About" />
            </NavLink>
          </div>
          <div
            onClick={() => {
              prompt("logout");
            }}
            className="lastSettings col-12"
          >
            <IconWithArrow icon="fas fa-sign-out-alt fa-2x" label="Log Out" />
          </div>
        </div>
      </div>
    );
  }
}
