import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch
  //  Redirect
} from "react-router-dom";
import "./App.css";
import ForgotPassword from "./pages/Login/ForgotPassword";
import ResetPassword from "./pages/Login/ResetPassword";
import Settings from "./pages/Settings/Settings";
import About from "./pages/Settings/About";

import "bootstrap/dist/css/bootstrap.min.css";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
    };
  }

  PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props => (
        <Component
          {...props}
          apiUrl={apiUrl}
          token={localStorage.getItem("token")}
        />
      )}
    />
  );

  render() {
    const NoAuthRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={props => <Component {...props} apiUrl={apiUrl} />}
      />
    );

    const noAuthContainer = () => {
      return (
        <div className="main-app mb-5">
          <NoAuthRoute
            exact
            path="/forgotPassword"
            component={ForgotPassword}
          />
          <NoAuthRoute
            exact
            path="/appUsers/:token/forgotPassword"
            component={ResetPassword}
          />
        </div>
      );
    };


    const authContainer = () => {
      return (
        <div className="main-app">
          <Route exact path="/" component={Settings} />
          <this.PrivateRoute exact path="/settings" component={Settings} />
          <this.PrivateRoute exact path="/about" component={About} />
          <NoAuthRoute
            exact
            path="/forgotPassword"
            component={ForgotPassword}
          />
        </div>
      );
    };

    return (
      <Router>
        <Switch>
          <Route exact path={["/forgotPassword", "/appUsers/:token/forgotPassword"]} component={noAuthContainer} />
          {/* <Route exact path="/resetPassword" component={ResetPassword} /> */}
          <Route component={authContainer} />
        </Switch>
      </Router>
    );
  }
}
