import React from "react";

export default function TermsandPrivacy(props) {
  
  return (
    <div className="w-100 terms-privacy">
      <div className="col-md-12 d-flex justify-content-center text-center">
        <a target="_blank" rel="noopener noreferrer" href="https://wiki.locatorx.com/privacy-policy/" className="mr-2">Privacy Policy</a>
        |
        <a target="_blank" rel="noopener noreferrer" href="https://wiki.locatorx.com/terms-of-use/" className="ml-2">Terms of Use</a>
      </div>
      <div className="w-100 text-center mt-1">
      <span>Copyright ⓒ2021 LocatorX. All Right Reserved</span>
      </div>
    </div>
  );
}
