import React from "react";
import locatorx from "../../assets/locatorx.png";
import request from "superagent";
import { Form } from "react-bootstrap";
import NotificationModal from "../../components/NotificationModal";
import TermsandPrivacy from "../../components/TermsandPrivacy";
import Typography from "@material-ui/core/Typography";

export default class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationModalShow: false,
      confirmationText: "",
      confirmationModalColor: "bg-success",
      email: "",
      submitSuccess: false,
    };
  }

  componentDidMount() {
    const { token, appUserId } = this.props.match.params;

    localStorage.setItem("token", token);
    localStorage.setItem("appUserId", appUserId);
    this.setState({ token, appUserId });
  }

  handleConfirmationModal(
    confirmationText,
    onSubmitResult,
    confirmationModalColor = "bg-success"
  ) {
    this.setState({
      confirmationText,
      confirmationModalShow: true,
      confirmationModalColor,
    });
    setTimeout(
      function () {
        this.setState({ confirmationModalShow: false });
        if (onSubmitResult === true) {
          prompt("logout");
        }
      }.bind(this),
      850
    );
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      const { apiUrl } = this.props;
      const { email } = this.state;
      const body = {
        email,
        sourceSuffix: "LogisticsPro",
      };

      request
        .post(`${apiUrl}appUsers/forgotPasswordRequest`)
        .send(body)
        .then((response) => {
          if (response.body.success === true) {
            this.setState({ submitSuccess: true });
          }
        })
        .catch((err) => {
          this.handleConfirmationModal(
            `Error: ${err.message} ${err.response}`,
            true,
            "bg-danger"
          );
        });
    }
    this.setState({ validated: true });
  }

  render() {
    const {
      confirmationModalShow,
      confirmationText,
      confirmationModalColor,
      submitSuccess,
    } = this.state;
    const requiredField = (
      <Form.Control.Feedback
        className="bg-primary invalid-feedback w-25 rounded text-white bg-danger px-1 font-weight-bold text-center position-relative speech-bubble mt-2"
        type="invalid"
      >
        Required Field
      </Form.Control.Feedback>
    );
    let modalClose = () =>
      this.setState({
        modalShow: false,
      });

    return (
      <Form
        onSubmit={(event) => {
          this.handleSubmit(event);
        }}
        className="container py-3 d-flex flex-column justify-content-between h-100"
      >
        <NotificationModal
          confirmationModalShow={confirmationModalShow}
          modalClose={modalClose}
          confirmationText={confirmationText}
          confirmationModalColor={confirmationModalColor}
        />
        {submitSuccess ? (
          <div className="row h-100 justify-content-center">
            <div className="col-md-12 text-center">
              <div className="w-100 my-3 text-center">
                <img alt="company logo" src={locatorx} width="275" />
              </div>
              <div className="w-100 d-flex flex-column mt-5">
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 600,
                    textAlign: "center",
                    margin: "2rem 0",
                  }}
                >
                  Check your email for the Forgot Password Request email.
                </Typography>
                <Typography
                  variant="h6"
                  style={{ textAlign: "center", margin: "2rem 0", fontWeight:500 }}
                >
                  Some email servers flag this email as spam or junk. If you
                  don't receive the email in your inbox within 5 minutes, check
                  your Spam or Junk email folders.
                </Typography>
              </div>
            </div>
          </div>
        ) : (
          <div className="row h-100 justify-content-center">
            <div className="col-xs-12 col-md-6 ">
              <div className="w-100 my-3 text-center">
                <img alt="company logo" src={locatorx} width="275" />
              </div>
              <Form.Group className="w-100 my-5">
                <Form.Label className="mt-2 mb-0 font-weight-bold">
                  Email
                </Form.Label>
                <Form.Control
                  required
                  name="email"
                  className="form-control bg-white"
                  aria-label="Large"
                  field="email"
                  value={this.state.email}
                  onChange={(event) => {
                    this.setState({ email: event.target.value });
                  }}
                />
                {requiredField}
              </Form.Group>
              <div className="w-100">
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  style={{ color: "#3D5BA9 !important" }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
        <TermsandPrivacy />
      </Form>
    );
  }
}
