import React, { Component } from "react";
import { Modal } from "react-bootstrap";

export default class NotificationModal extends Component {
  render() {
    const {confirmationModalShow, modalClose, confirmationText, color = 'bg-success', icon = 'fas fa-check-circle mr-2'} = this.props
    return (
      <Modal show={confirmationModalShow} onHide={modalClose} centered>
        <Modal.Body className={`${color} text-white font-weight-bold text-center rounded shadow`}>
          <i className={`${icon} mr-2`}></i>
          {confirmationText}
        </Modal.Body>
      </Modal>
    );
  }
}
